import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import styles from "./ContactButton.module.css";
import { LinkedinFilled } from "@ant-design/icons";

interface ContactButtonProps {
	label: string;
	onClick?: () => void;
	htmlType: "button" | "submit" | "reset";
	type?: "default" | "primary";
}

const ContactButton: FC<ContactButtonProps> = (props) => {
	function onLinkedIn() {
		window.open("https://www.linkedin.com/company/spatialnetics/", "_blank");
	}
	return (
		<div className={styles.ContactButton} data-testid="ContactButton">
			<Row justify="center">
				<Button
					className={styles.button}
					type={props.type ? props.type : "primary"}
					htmlType={props.htmlType}
					onClick={props.onClick}
				>
					{props.label}
				</Button>
			</Row>
			<Row justify="center" gutter={6}>
				<Col>
					<LinkedinFilled
						className={`${styles.socialMediaIcon} ${styles.linkedin}`}
						onClick={() => onLinkedIn()}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default ContactButton;
