import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Carousel, Row } from "antd";
import styles from "./Services.module.css";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import { ServiceInfo } from "../../models/ServiceInfo";
import Service from "./Service/Service";
import useSizeBreakpoint from "../../Hooks/SizeBreakpoint";

interface ServicesProps { }

const Services: FC<ServicesProps> = () => {
	const { t, i18n } = useTranslation();
	const sizeBreakpoint = useSizeBreakpoint();
	const [cards, setCards] = React.useState<ServiceInfo[] | undefined>(undefined);

	// Initialize cards from current translation file
	function loadItems(json: any) {
		const items: ServiceInfo[] = [];
		if (!json?.services?.cards) return;
		for (let card of json.services.cards) {
			items.push({
				title: card.title,
				description: card.description,
				src: card.src,
				bullets: card.bullets,
			});
		}
		setCards(items);
	}

	// Get the current translation file content to initialize cards
	const initCards = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItems(translationData);
	};

	useEffect(() => {
		onChangeLanguage(initCards);
	}, [i18n]);

	return (
		<div className={styles.Services} data-testid="Services">
			<TabContentHeader
				title={t("services.header.title")}
				description={t("services.header.description")}
			></TabContentHeader>
			<Carousel
				autoplay
				autoplaySpeed={8000}
				arrows={false}
			>
				{cards &&
					cards.map((service, index) => (
						<div key={index}>
							<Row key={index} justify="center">
								<Service service={service}></Service>
							</Row>
						</div>
					))}
			</Carousel>
		</div>
	);
};

export default Services;
