import { useEffect, useState } from "react";

// 1-base so can check validity with val? instead of val !== false?
export enum ScreenSize {
	XXS = 1,
	XS = 2,
	SM = 3,
	MD = 4,
	LG = 5,
	XL = 6,
}
export const DEFAULT_WIDTH_BREAKPOINTS = new Map<ScreenSize, Number>([
	[ScreenSize.XL, Number.MAX_SAFE_INTEGER],
	[ScreenSize.LG, 1200],
	[ScreenSize.MD, 992],
	[ScreenSize.SM, 768],
	[ScreenSize.XS, 576],
	[ScreenSize.XXS, 400],
]);
export const DEFAULT_HEIGHT_BREAKPOINTS = new Map<ScreenSize, Number>([
	[ScreenSize.XL, Number.MAX_SAFE_INTEGER],
	[ScreenSize.LG, 1000],
	[ScreenSize.MD, 750],
	[ScreenSize.SM, 500],
	[ScreenSize.XS, 350],
]);

function getCurrentSize(ref: Map<ScreenSize, Number>, currentSize: number): ScreenSize | false {
	let breakpoint: boolean | ScreenSize = false;
	ref.forEach((value, key) => {
		if (currentSize < Number(value)) {
			breakpoint = key;
		}
	});
		return breakpoint;
}

// Hook that gets triggered when the the screen width, height or both reaches a certain limit
const useSizeBreakpoint = (
	widthRef: Map<ScreenSize, Number> = DEFAULT_WIDTH_BREAKPOINTS,
	heightRef: Map<ScreenSize, Number> = DEFAULT_HEIGHT_BREAKPOINTS
) => {
	const [isWidthBreakpoint, setIsWidthBreakpoint] = useState<ScreenSize | boolean>(false);
	const [isHeightBreakpoint, setIsHeightBreakpoint] = useState<ScreenSize | boolean>(false);
	const [isCombinedBreakpoint, setIsCombinedBreakpoint] = useState<ScreenSize | boolean>(false);
	const [widthPx, setWidthPx] = useState<number>(0);
	const [heightPx, setHeightPx] = useState<number>(0);
	const [orientation, setOrientation] = useState<string>('Portrait');
	const [swapped, setSwapped] = useState<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			let width = window.innerWidth;
			let height = window.innerHeight;
			let screenWidth = window.screen.availWidth;
			let screenHeight = window.screen.availHeight;

			const portrait = window.matchMedia('(orientation: portrait)').matches;
			setOrientation(portrait? 'Portrait': 'Landscape');
			let s = false;
			if ((portrait && screenWidth > screenHeight) || (!portrait && screenWidth < screenHeight)) {
				const temp = screenWidth;
				screenWidth = screenHeight;
				screenHeight = temp;
			}
			width = Math.min(width, screenWidth);
			height = Math.min(height, screenHeight);
			setSwapped(s);
			setWidthPx(width);
			setHeightPx(height);
			const widthBreakPoint = getCurrentSize(widthRef, width);
			const heightBreakpoint = getCurrentSize(heightRef, height);

			setIsWidthBreakpoint(widthBreakPoint);
			setIsHeightBreakpoint(heightBreakpoint);

			if (widthBreakPoint && heightBreakpoint) {
				setIsCombinedBreakpoint(Math.min(widthBreakPoint, heightBreakpoint));
			} else if (widthBreakPoint) {
				setIsCombinedBreakpoint(widthBreakPoint);
			} else if (heightBreakpoint) {
				setIsCombinedBreakpoint(heightBreakpoint);
			} else {
				setIsCombinedBreakpoint(false);
			}
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return { width: isWidthBreakpoint, height: isHeightBreakpoint, combined: isCombinedBreakpoint, widthPx: widthPx, heightPx: heightPx, orientation: orientation, swapped: swapped };
};

export default useSizeBreakpoint;
