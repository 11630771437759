import { Col, Row } from "antd";
import React, { FC } from "react";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import DescriptionWithLink from "../../TabContentHeader/DescriptionWithLink/DescriptionWithLink";
import { Project } from "../Projects";
import styles from "./ProjectSmall.module.css";

interface ProjectSmallProps {
	card: Project;
	cardIdx: number;
}

const ProjectSmall: FC<ProjectSmallProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();
	function getImageStyle() {
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.imageSM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.imageXS;
		return styles.imageXXS;
	}

	function getDetailsStyle() {
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.detailsSM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.detailsXS;
		return styles.detailsXXS;
	}

	function getTitleStyle() {
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.titleSM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.titleXS;
		return styles.titleXXS;
	}

	function getDescriptionStyle() {
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.descriptionSM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.descriptionXS;
		return styles.descriptionXXS;
	}
	return (
		<div className={styles.ProjectSmall} data-testid="ProjectSmall">
			<Row className={styles.row} justify={props.cardIdx % 2 === 0 ? "start" : "end"}>
				{/* Image */}
				<Col order={props.cardIdx % 2 === 0 ? 1 : 2}>
					<img className={`${styles.image} ${getImageStyle()}`} alt="example" src={props.card.src} />
				</Col>
				{/* Details */}
				<Col
					className={`${styles.details} ${props.cardIdx % 2 === 0 ? styles.overlapLeft : styles.overlapRight
						} ${getDetailsStyle()}`}
					order={props.cardIdx % 2 !== 0 ? 2 : 1}
				>
					{/* Title */}
					<h1 className={`${styles.title} ${getTitleStyle()}`}>{props.card.title}</h1>
					{/* Description */}
					<p className={`${styles.description} ${getDescriptionStyle()}`}>
						<DescriptionWithLink
							description={props.card.description}
							link={props.card.clientLink}
							email={false}
							title={props.card.clientTitle}
						></DescriptionWithLink>
					</p>
				</Col>
			</Row>
		</div>
	);
};

export default ProjectSmall;
