import React, { FC, useEffect } from "react";
import styles from "./Mission.module.css";
import { useTranslation } from "react-i18next";
import { CardInfo } from "../../models/CardInfo";
import { getCSSVariableValue, getSVG } from "../../images/util";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import { Col, Row } from "antd";
import BasicCard from "./BasicCard/BasicCard";
interface MissionProps { }

const Mission: FC<MissionProps> = () => {
	const { t, i18n } = useTranslation();
	const [cards, setCards] = React.useState<CardInfo[] | undefined>(undefined);

	// Initialize cards from current translation file
	function loadItems(json: any) {
		const items: CardInfo[] = [];
		if (!json?.mission?.cards) return;
		for (let card of json.mission.cards) {
			items.push({
				title: card.title,
				description: card.description,
				svg: getSVG(card.src, {
					height: getCSSVariableValue("--about-img-size"),
					width: getCSSVariableValue("--about-img-size"),
					marginTop: getCSSVariableValue("--about-image-top-margin"),
					marginBottom: getCSSVariableValue("--about-image-bottom-margin"),
					fill: getCSSVariableValue("--sn-primary"),
				}),
				src: card.src,
			});
		}
		setCards(items);
	}

	// Get the current translation file content to initialize cards
	const initCards = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItems(translationData);
	};

	useEffect(() => {
		onChangeLanguage(initCards);
	}, [i18n]);

	const gutter = Number(getCSSVariableValue("--gutter"));
	return (
		<div className={styles.Mission} data-testid="mission">
			<TabContentHeader
				title={t("mission.header.title")}
				description={t("mission.header.description")}
			></TabContentHeader>
			<Row gutter={[gutter, gutter]} justify="center" className={styles.cardContainer}>
				{cards &&
					cards.map((card: CardInfo, index: number) => (
						<Col key={index} className={styles.cardCol}>
							<BasicCard card={card}></BasicCard>
						</Col>
					))}
			</Row>
		</div>
	);
};

export default Mission;
