import { Row, Typography } from "antd";
import React, { FC } from "react";
import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import ContactButton from "../ContactButton/ContactButton";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import LatestNews from "./LatestNews/LatestNews";
import { BasicHeader } from "../../models/BasicHeader";

interface HomeProps {
	onContact: () => void;
	onReadMore: () => void;
}

const Home: FC<HomeProps> = (props) => {
	const [card, setCard] = React.useState<BasicHeader | undefined>(undefined);
	const { t, i18n } = useTranslation();
	const backgroundRef = React.useRef<HTMLImageElement>(null);

	// Initialize cards from current translation file
	function loadItem(json: any) {
		const cards: any[] = json?.news?.cards;
		if (!cards) return;

		if (cards.length > 0) {
			const description = cards[0].description.replace('{{link}}', cards[0].link);
			setCard({
				title: cards[0].title,
				description: description,
				src: cards[0].src
			});
		}
	}

	// Get the current translation file content to initialize cards
	const initCard = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItem(translationData);
	};

	React.useEffect(() => {
		onChangeLanguage(initCard);
	}, [i18n]);

	return (
		<div className={styles.Home} data-testid="Home">
			<div className={`${styles.overlap}`} >
				{/* Image */}
				<div className={styles.overlapContent}>
					<img ref={backgroundRef} className={styles.clippedImage} src="images/home-widescreen.jpg" alt="Spatialnetics" />

					{/* Latest news */}
					<div className={`${styles.newsOverlap}`}>
						{card &&
							<LatestNews card={card} onReadMore={props.onReadMore}></LatestNews>
						}
					</div>
				</div>
				<div className={`${styles.overlapContent}`}>
					{/* Header */}
					<TabContentHeader
						title={t("home.header.title")}
						description={t("home.header.description")}
					></TabContentHeader>
					{/* Contact */}
					<Row justify="center" className={styles.contact}>
						<ContactButton
							label={t("general.contactLabel")}
							onClick={props.onContact}
							htmlType="button"
							type="default"
						></ContactButton>
					</Row>
				</div>
			</div>
		</div >
	);
};

export default Home;
