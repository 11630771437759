import React, { FC } from 'react';
import styles from './News.module.css';
import TabContentHeader from '../TabContentHeader/TabContentHeader';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import loadTranslationJson, { onChangeLanguage } from '../../locales/util';
import NoData from '../NoData/NoData';
import DescriptionWithLink from '../TabContentHeader/DescriptionWithLink/DescriptionWithLink';

interface NewsProps { }

export interface NewsItem {
  title: string,
  src: string,
  description: string[],
  dateStr?: string;
  link: string;
}
const News: FC<NewsProps> = (props: NewsProps) => {

  const [cards, setCards] = React.useState<NewsItem[]>([]);
  const { t, i18n } = useTranslation();

  // Initialize cards from current translation file
  function loadItem(json: any) {
    const jsonCards: any[] = json?.news?.cards;
    if (!jsonCards) return;

    const items: NewsItem[] = [];
    for (let item of jsonCards) {
      items.push({
        title: item.title,
        description: item.description.split('\n'),
        src: item.src,
        dateStr: item.date,
        link: item.link || ''
      });
    }
    setCards(items);
  }

  // Get the current translation file content to initialize cards
  const initNews = async () => {
    const translationData = await loadTranslationJson();
    // Access the translated JSON data and perform operations
    loadItem(translationData);
  };

  React.useEffect(() => {
    onChangeLanguage(initNews);
  }, [i18n]);

  return (
    <div className={styles.News} data-testid="News">
      {/* Header */}
      <TabContentHeader
        title={t("news.title")}
        description={""}
      ></TabContentHeader>
      {/* News */}
      <Row justify="center" style={{ height: "100%" }}>
        {cards.length > 0 ?
          cards.map((item: NewsItem, index: number) => (
            <Col key={index} span={24} className={styles.card}>
              {/* Title */}
              <Row justify="center"><h1 className={`${styles.pCustom} ${styles.title}`}>{item.title}</h1></Row>
              {/* Date */}
              <Row justify="center"><p className={`${styles.pCustom} ${styles.date}`}>{item.dateStr}</p></Row>
              {/* Image */}
              <Row justify="center"><img alt="news" className={styles.img} src={item.src} /></Row>
              {/* Description */}
              <Row justify="center" key={index}>
                {item.description.map((d: string, index: number) => (
                  <Col span={24} className={styles.descriptionParagraph}><DescriptionWithLink description={d} link={item.link} email={true} styleClass={styles.pCustom}></DescriptionWithLink></Col>
                ))}
              </Row>
              <br></br>
            </Col>
          )) :
          (
            // No Data
            <NoData></NoData>
          )}
      </Row>
    </div >
  );
};

export default News;
