import React, { FC } from "react";
import styles from "./DescriptionWithLink.module.css";

interface DescriptionWithLinkProps {
	description: string;
	email: boolean;
	link: string;
	title?: string;
	styleClass?: any;
}

const DescriptionWithLink: FC<DescriptionWithLinkProps> = (props) => {
	const [description1, setDescription1] = React.useState<string>("");
	const [description2, setDescription2] = React.useState<string>("");
	const [link, setLink] = React.useState<string>("");
	const [title, setTitle] = React.useState<string>("");

	React.useEffect(() => {
		const tokens = props.description.split("{{link}}");
		setDescription1(tokens[0]);
		setDescription2(tokens[1]);
		setLink(props.email ? `mailto:${props.link}` : props.link);
		setTitle(props.title ? props.title : props.link);
	}, [props.description]);
	return (
		<p className={`${styles.DescriptionWithLink} ${props.styleClass}`} data-testid="DescriptionWithLink">
			{description1}
			{description2 && <a className={styles.link} href={link}>
				{title}
			</a>}
			{description2}
		</p>
	);
};

export default DescriptionWithLink;
