import React, { FC, useEffect } from "react";
import styles from "./Member.module.css";
import { Card } from "antd";
import MemberHeader from "../MemberHeader/MemberHeader";
import MemberExpanded from "../MemberExpanded/MemberExpanded";
import { TeamMember } from "../../../models/TeamMember";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import MemberSmall from "../MemberSmall/MemberSmall";

interface MemberProps {
	member: TeamMember;
	onClick: () => void;
}

// Display a team member card, with details on the right if the card was clicked to be expanded
const Member: FC<MemberProps> = (props) => {
	const [expanded, setExpanded] = React.useState<boolean>(props.member.expanded);
	const sizeBreakpoint = useSizeBreakpoint();
	useEffect(() => {
		setExpanded(props.member.expanded);
	}, [props.member.expanded]);
	return (
		<div className={styles.Member} data-testid="Member">
			{/* Details below for x-smalls screens */}
			{sizeBreakpoint.width === ScreenSize.XS || sizeBreakpoint.width === ScreenSize.XXS ? (
				<MemberSmall member={props.member} onClick={props.onClick}></MemberSmall>
			) : (
				// Details on the right for larger screens
				<div>
					{expanded ? (
						<MemberExpanded member={props.member} onClick={props.onClick}></MemberExpanded>
					) : (
						<Card
							className={styles.card}
							hoverable={true}
							bodyStyle={{ padding: 0 }}
							onClick={props.onClick}
						>
							<MemberHeader
								member={props.member}
								expandedDown={false}
								expandedRight={false}
							></MemberHeader>
						</Card>
					)}
				</div>
			)}
		</div>
	);
};

export default Member;
