import { Col, Row } from "antd";
import React, { FC } from "react";
import styles from "./MemberDetails.module.css";
import { LinkedinFilled } from "@ant-design/icons";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import { TeamMember } from "../../../models/TeamMember";

interface MemberDetailsProps {
	member: TeamMember;
	large: boolean;
}

const MemberDetails: FC<MemberDetailsProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();

	// Functions to get style classes based on the current screen width
	function getDetailsClass(): string {
		let classStr = styles.details;
		if (sizeBreakpoint.width === ScreenSize.XXS) return `${classStr} ${styles.detailsXxs}`;
		if (sizeBreakpoint.width === ScreenSize.XS) return `${classStr} ${styles.detailsXs}`;
		if (sizeBreakpoint.width === ScreenSize.SM) return `${classStr} ${styles.detailsSm}`;
		if (sizeBreakpoint.width === ScreenSize.MD) return `${classStr} ${styles.detailsMd}`;
		return `${classStr} ${styles.detailsDefault}`;
	}
	function getLinkedInClass(): string {
		let classStr = styles.linkedIn;
		if (sizeBreakpoint.width === ScreenSize.XXS) return `${classStr} ${styles.linkedInXxs}`;
		if (sizeBreakpoint.width === ScreenSize.XS) return `${classStr} ${styles.linkedInXs}`;
		if (sizeBreakpoint.width === ScreenSize.SM) return `${classStr} ${styles.linkedInSm}`;
		return `${classStr} ${styles.linkedInDefault}`;
	}
	function onLinkedIn() {
		window.open(props.member.linkedIn, "_blank");
	}
	return (
		<div
			className={`${styles.MemberDetails} ${props.large ? styles.MemberDetailsLarge : styles.MemberDetailsSmall}`}
			data-testid="MemberDetails"
		>
			<Col>
				{/* Details */}
				<div className={getDetailsClass()}>{props.member.details}</div>
				{/* Link to Linkedin profile */}
				<Row justify="center">
					<LinkedinFilled className={getLinkedInClass()} onClick={() => onLinkedIn()} />
				</Row>
			</Col>
		</div>
	);
};

export default MemberDetails;
