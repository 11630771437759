import React, { FC } from 'react';
import styles from './LatestNews.module.css';
import { BasicHeader } from '../../../models/BasicHeader';
import { Button, Col, Row } from 'antd';
import useSizeBreakpoint, { ScreenSize } from '../../../Hooks/SizeBreakpoint';
import { useTranslation } from 'react-i18next';
import { getCSSVariableBaseValue, getCSSVariableValue } from '../../../images/util';
interface LatestNewsProps {
  card: BasicHeader;
  onReadMore: () => void;
}

const LatestNews: FC<LatestNewsProps> = (props) => {
  const [imageRatio, setImageRatio] = React.useState<number>(1);
  const [language, setLanguage] = React.useState<string>();
  const [imageHeight, setImageHeight] = React.useState<string>('auto');
  const [imageWidth, setImageWidth] = React.useState<string>('auto');
  const [cardWidth, setCardWidth] = React.useState<string>('auto');
  const [cardHeight, setCardHeight] = React.useState<string>('auto');
  const [descriptionHeight, setDescriptionHeight] = React.useState<string>('auto');
  const [fadeDescription, setFadeDescription] = React.useState<boolean>(false);
  const [maxLines, setMaxLines] = React.useState<number>(Number.MAX_SAFE_INTEGER);

  const { t, i18n } = useTranslation();
  const sizeBreakpoint = useSizeBreakpoint();
  const mainDivRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLElement>(null);
  const descriptionRef = React.useRef<HTMLDivElement>(null);

  // Image ratio
  const img = document.createElement('img');
  img.onload = () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    setImageRatio(width / height);
  };
  img.src = props.card.src;

  // CSS constants
  const padding = Number(getCSSVariableValue('--latest-news-padding'));
  const maxWidth = Number(getCSSVariableValue('--latest-news-max-width'));
  const maxHeight = Number(getCSSVariableValue('--latest-news-max-height'));
  const cardWidthPerc = Number(getCSSVariableValue('--latest-news-card-width-perc'));
  const lineHeight = Number(getCSSVariableValue('--latest-news-description-line-height'));
  const titleFontSize = Number(getCSSVariableBaseValue('--font-sm'));
  const titleLineHeight = Number(getCSSVariableValue('--latest-news-title-line-height'));

  function isHoriz(): boolean {
    if (typeof sizeBreakpoint.width !== "number") {
      return false;
    }
    return Number(sizeBreakpoint.width) > ScreenSize.SM;
  }

  function predictTitleHeight(text: string, containerWidth: number) {
    // Temp element to predict the title height
    const container = document.createElement('div');
    container.style.width = `${containerWidth}px`;
    container.style.height = 'auto';
    container.style.fontSize = `${titleFontSize}px`;
    container.style.lineHeight = `${titleLineHeight}px`;
    container.style.backgroundColor = 'red';
    container.style.visibility = 'hidden';
    container.style.position = 'absolute';
    container.style.whiteSpace = 'normal';
    container.style.wordWrap = 'break-word';
    container.style.overflowWrap = 'break-word';
    container.innerText = text;
    document.body.appendChild(container);

    const height = container.clientHeight;

    // Remove temp element
    document.body.removeChild(container);

    return height;
  }

  function updateDescription() {
    const availWidth = mainDivRef.current?.offsetWidth;
    let availHeight = mainDivRef.current?.offsetHeight;
    const buttonHeight = buttonRef.current?.offsetHeight;
    let imHeight = 0;
    let imWidth = 0;
    let cHeight = 0;
    let cWidth = 0;
    let dHeight = 0;
    let nLines = 0;

    if (isHoriz()) {
      // Horizontal layout
      if (availWidth && imageRatio) {
        cWidth = availWidth * cardWidthPerc / 100;
        cWidth = Math.min(cWidth, maxWidth);
        imWidth = cWidth / 2 - 2 * padding;
        imHeight = imWidth / imageRatio;
        cHeight = imHeight + 2 * padding;
      }
    } else {
      // Vertical layout
      if (availHeight && availWidth && imageRatio) {
        availHeight = Math.min(availHeight, maxHeight);
        imHeight = availHeight / 2 - 4 * padding;
        imWidth = imHeight * imageRatio;
        cWidth = imWidth + 2 * padding;
        if (cWidth > availWidth * cardWidthPerc / 100) {
          cWidth = availWidth * cardWidthPerc / 100;
          imWidth = cWidth - 2 * padding;
          imHeight = imWidth / imageRatio;
        }
        cHeight = imHeight * 2 + 2 * padding;
      }
    }
    const titleHeight = predictTitleHeight(props.card.title, imWidth - 2 * padding);
    // Fix description height and max number of lines before ellipsis
    if (buttonHeight) {
      dHeight = imHeight - titleHeight - buttonHeight;
      nLines = Math.round(dHeight / lineHeight);
      dHeight = lineHeight * nLines;
    }

    setDescriptionHeight(`${dHeight}px`);
    setImageWidth(`${imWidth}px`);
    setImageHeight(`${imHeight}px`);
    setCardWidth(`${cWidth}px`);
    setCardHeight(`${cHeight}px`);
    setMaxLines(nLines);
  }

  // Update description height on title change or screen resize
  React.useEffect(() => {

    const resizeObserver = new ResizeObserver(() => {

      // Trigger the effect after a brief delay to ensure other components have updated
      const timeoutId = setTimeout(() => {
        updateDescription();
      }, 100);

      return () => {
        clearTimeout(timeoutId);
      };
    });

    if (mainDivRef.current) {
      resizeObserver.observe(mainDivRef.current);
    }

    return () => {
      if (mainDivRef.current) {
        resizeObserver.unobserve(mainDivRef.current);
      }
    };
  }, [props.card.title, sizeBreakpoint.width, sizeBreakpoint.height, sizeBreakpoint.combined, imageRatio]);

  // Update fading effect on description height change
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {

      // Trigger the effect after a brief delay to ensure other components have updated
      const timeoutId = setTimeout(() => {
        let fade = false;
        if (descriptionRef?.current?.scrollHeight) {
          fade = descriptionRef?.current?.scrollHeight > Number(descriptionHeight.replace('px', ''));
        }
        setFadeDescription(fade);
      }, 100);

      return () => {
        clearTimeout(timeoutId);
      };
    });

    if (descriptionRef.current) {
      resizeObserver.observe(descriptionRef.current);
    }

    return () => {
      if (descriptionRef.current) {
        resizeObserver.unobserve(descriptionRef.current);
      }
    };
  }, [descriptionHeight]);

  return (
    <div ref={mainDivRef} className={`${styles.LatestNews} ${styles.centeredRow}`} data-testid="LatestNews">
      <div style={{ width: cardWidth, height: cardHeight }}>
        {/* Banner */}
        <Row>
          <div className={`${styles.banner}`}>{t('home.news.title')}</div>
        </Row>
        <Row className={styles.cardContent} justify="center" >
          <Col xs={24} sm={24} md={12} order={isHoriz() ? 0 : 1}
            className={`${styles.detailsContainer} ${isHoriz() ? styles.detailsContainerHoriz : styles.detailsContainerVert}`}>
            {/* Title */}
            <h1 className={styles.title}>{props.card.title}</h1>
            {/* Description */}
            <p ref={descriptionRef} className={`${styles.description} ${fadeDescription ? styles.fadeText : null}`}
              style={{ maxHeight: descriptionHeight, WebkitBoxOrient: "vertical", WebkitLineClamp: maxLines }}>
              {props.card.description}
            </p>
            {/* Read more button */}
            <Button ref={buttonRef} className={styles.readMoreButton} type="text" htmlType="button" onClick={props.onReadMore} block={true}>
              <Row align="middle" justify="center">
                <Col>{t('home.news.readMore')}</Col>
              </Row>
            </Button>
          </Col>
          {/* Image */}
          <Col xs={24} sm={24} md={12} order={isHoriz() ? 1 : 0}
            className={`${styles.imgContainer} ${isHoriz() ? styles.imgContainerHoriz : styles.imgContainerVert}`}>
            <Row justify="center" className={styles.paddingMargin0}>
              <img alt="news" src={props.card.src} className={isHoriz() ? styles.imgHoriz : styles.imgVert}
                style={{ width: imageWidth, height: imageHeight }}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div >
  );
};

export default LatestNews;
