import { Col, Row } from "antd";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import ContactButton from "../ContactButton/ContactButton";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import styles from "./Partners.module.css";

interface PartnersProps {
	onContact: () => void;
}

interface Partner {
	src: string;
	link: string;
}

const Partners: FC<PartnersProps> = (props) => {
	const { t, i18n } = useTranslation();
	const [partners, setPartners] = React.useState<Partner[] | undefined>(undefined);

	// Initialize partners from current translation file
	function loadItems(json: any) {
		const items: Partner[] = [];
		if (!json?.partners?.list) return;
		for (let partner of json.partners.list) {
			items.push({
				src: partner.src,
				link: partner.link,
			});
		}
		setPartners(items);
	}

	// Get the current translation file content to initialize partners
	const initCards = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItems(translationData);
	};

	useEffect(() => {
		onChangeLanguage(initCards);
	}, [i18n]);

	function onPartner(partner: Partner) {
		window.open(partner.link, "_blank");
	}

	return (
		<div className={styles.Partners} data-testid="Partners">
			<TabContentHeader
				title={t("partners.header.title")}
				description={t("partners.header.description")}
			></TabContentHeader>
			{partners && (
				<Row align="middle" justify="center">
					{partners.map((partner: Partner, index: number) => (
						<Col key={index}>
							<img
								className={styles.image}
								src={partner.src}
								alt={partner.link}
								onClick={() => onPartner(partner)}
							></img>
						</Col>
					))}
				</Row>
			)}
			<br></br>
			<div className={styles.contact}>{t("partners.contact")}</div>
			<ContactButton
				label={t("general.contactLabel")}
				onClick={props.onContact}
				htmlType="button"
				type="default"
			></ContactButton>
		</div>
	);
};

export default Partners;
