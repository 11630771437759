import { Card, Col, Row } from "antd";
import React, { FC } from "react";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import { TeamMember } from "../../../models/TeamMember";
import MemberDetails from "../MemberDetails/MemberDetails";
import MemberHeader from "../MemberHeader/MemberHeader";
import styles from "./MemberExpanded.module.css";

interface MemberExpandedProps {
	member: TeamMember;
	onClick: () => void;
}

// Display a team member info with full details
const MemberExpanded: FC<MemberExpandedProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();
	function getMainClass(): string {

		if (sizeBreakpoint.width === ScreenSize.XXS) return styles.MemberExpandedXxs;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.MemberExpandedXs;
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.MemberExpandedSm;
		if (sizeBreakpoint.width === ScreenSize.MD) return styles.MemberExpandedMd;
		return styles.MemberExpandedDefault;
	}
	return (
		<div className={getMainClass()} data-testid="MemberExpanded">
			<Card bodyStyle={{ padding: 0 }} onClick={props.onClick} hoverable={true}>
				<Row>
					<Col className={styles.HeaderContainer}>
						<MemberHeader member={props.member} expandedDown={false} expandedRight={true}></MemberHeader>
					</Col>
					<MemberDetails member={props.member} large={true}></MemberDetails>
				</Row>
			</Card>
		</div>
	);
};

export default MemberExpanded;
