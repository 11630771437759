import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Team.module.css";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import { Col, Row } from "antd";
import { getCSSVariableValue } from "../../images/util";
import Member from "./Member/Member";
import { TeamMember } from "../../models/TeamMember";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";

interface TeamProps { }

const Team: FC<TeamProps> = () => {
	const { t, i18n } = useTranslation();
	const [cards, setCards] = React.useState<TeamMember[] | undefined>(undefined);
	const gutter = Number(getCSSVariableValue("--gutter"));

	// Callback for click on card
	function onCard(title: string) {
		// Close all details except the card that just got clicked
		const updatedCards = cards?.map((card) => {
			if (card.title === title) {
				return { ...card, expanded: !card.expanded };
			}
			return { ...card, expanded: false };
		});

		setCards(updatedCards);
	}

	// Initialize cards from current translation file
	function loadItems(json: any) {
		const items: TeamMember[] = [];
		if (!json?.team?.cards) return;
		for (let card of json.team.cards) {
			items.push({
				title: card.title,
				description: card.position,
				details: card.description,
				src: card.src,
				linkedIn: card.linkedIn,
				expanded: false,
			});
		}
		setCards(items);
	}

	// Get the current translation file content to initialize cards
	const initCards = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItems(translationData);
	};

	useEffect(() => {
		onChangeLanguage(initCards);
	}, [i18n]);

	return (
		<div className={styles.Team} data-testid="Team">
			<TabContentHeader
				title={t("team.header.title")}
				description={t("team.header.description")}
			></TabContentHeader>
			<Row gutter={[gutter, gutter]} justify="center">
				{cards &&
					cards.map((card: TeamMember, index: number) => (
						<Col key={index}>
							<Member member={card} onClick={() => onCard(card.title)}></Member>
						</Col>
					))}
			</Row>
		</div>
	);
};

export default Team;
