import { Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import styles from "./Contact.module.css";
import emailjs from "emailjs-com";
import useSizeBreakpoint, { ScreenSize } from "../../Hooks/SizeBreakpoint";
import ContactButton from "../ContactButton/ContactButton";
import DescriptionWithLink from "../TabContentHeader/DescriptionWithLink/DescriptionWithLink";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";

const SERVICE_ID = "service_3p4u3r9";
const TEMPLATE_ID = "contact_form";
const USER_ID = "user_bxMzcGBTN3DSdho0msmxl";
export const INFO_EMAIL = "info@spatialnetics.com";

interface ContactProps { }

interface UserForm {
	name: string;
	organization?: string;
	email: string;
	message: string;
}

const Contact: FC<ContactProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();
	const [messageApi, contextHolder] = message.useMessage();
	const [submitted, setSubmitted] = React.useState(false);
	const [description, setDescription] = React.useState("");
	const { t, i18n } = useTranslation();

	const onSent = (userForm: UserForm) => {
		emailjs
			.send(
				SERVICE_ID,
				TEMPLATE_ID,
				{
					user_name: userForm.name,
					user_org: userForm.organization,
					user_email: userForm.email,
					message: userForm.message,
				},
				USER_ID
			)
			.then((response) => {
				messageApi.open({
					type: "success",
					content: t("alerts.success.emailSent"),
				});
			})
			.catch((error) => {
				messageApi.open({
					type: "error",
					content: t("alerts.error.emailFailed"),
				});
				console.error("Error sending email:", error);
			});
	};

	const onSendFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
	function getFormWidth(): string {
		if (sizeBreakpoint.width === ScreenSize.XXS) return "var(--form-width-xxs)";
		if (sizeBreakpoint.width === ScreenSize.XS) return "var(--form-width-xs)";
		return "var(--form-width)";
	}
	function getMarginBottom(): string {
		if (sizeBreakpoint.combined === ScreenSize.XXS) return "var(--form-margin-bottom-xxs)";
		if (sizeBreakpoint.combined === ScreenSize.XS) return "var(--form-margin-bottom-xs)";
		if (sizeBreakpoint.combined === ScreenSize.SM) return "var(--form-margin-bottom-sm)";
		return "var(--form-margin-bottom)";
	}
	function onSubmit() {
		setSubmitted(true);
	}

	const validateEmail = (_: any, value: string) => {
		// Regular expression pattern for email validation
		const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

		if (value && !emailPattern.test(value)) {
			return Promise.reject(t("contact.email.invalid"));
		}

		return Promise.resolve();
	};

	const initDescription = async () => {
		const json = await loadTranslationJson();
		if (!json?.contact?.header?.description)
			setDescription(json.contact.header.description);
	};

	React.useEffect(() => {
		onChangeLanguage(initDescription);
	}, [i18n]);

	return (
		<div className={styles.Contact} data-testid="Contact">
			{contextHolder}
			<TabContentHeader
				title={t("contact.header.title")}
				description={
					<div>
						<DescriptionWithLink
							description={description}
							email={true}
							link={INFO_EMAIL}
						></DescriptionWithLink>
					</div>
				}
			></TabContentHeader>
			<div className={styles.formContainer}>
				<Form
					layout="vertical"
					name="contact"
					onFinish={onSent}
					onFinishFailed={onSendFailed}
					autoComplete="off"
					style={{ minWidth: getFormWidth() }}
				>
					{/* Name */}
					<Form.Item
						label={t("contact.name.label")}
						name="name"
						rules={[{ required: true, message: t("contact.name.required") || "" }]}
						style={{ marginBottom: getMarginBottom() }}
					>
						<Input />
					</Form.Item>
					{/* Email */}
					<Form.Item
						label={t("contact.email.label")}
						name="email"
						rules={[
							{ required: true, message: t("contact.email.required") || "" },
							{ validator: submitted ? validateEmail : () => Promise.resolve() },
						]}
						style={{ marginBottom: getMarginBottom() }}
					>
						<Input />
					</Form.Item>
					{/* Organization */}
					<Form.Item
						label={t("contact.organization")}
						name="organization"
						style={{ marginBottom: getMarginBottom() }}
					>
						<Input />
					</Form.Item>
					{/* Message */}
					<Form.Item
						label={t("contact.message.label")}
						name="message"
						rules={[{ required: true, message: t("contact.message.required") || "" }]}
						style={{ marginBottom: getMarginBottom() }}
					>
						<TextArea rows={8} placeholder={t("contact.message.placeholder") || ""} />
					</Form.Item>
					{/* Send button */}
					<Form.Item className={styles.submitButton}>
						<ContactButton
							label={t("contact.send")}
							htmlType={"submit"}
							onClick={onSubmit}
							type="default"
						></ContactButton>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default Contact;
