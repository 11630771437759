import { Menu, Select } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import useSizeBreakpoint, { ScreenSize } from "../../Hooks/SizeBreakpoint";
import styles from "./NavBar.module.css";
import { Language, SUPPORTED_LANGUAGES } from "../../locales/util";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";

export enum NavBarItems {
	ABOUT = "about",
	CAREERS = "careers",
	CONTACT = "contact",
	HOME = "home",
	LANGUAGE = "language",
	MISSION = "mission",
	NEWS = "news",
	PARTNERS = "partners",
	PROJECTS = "projects",
	SERVICES = "services",
	SOLUTIONS = "solutions",
	TEAM = "team",
}

// Local storage keys
const LANGUAGE_KEY = "current_language";

// Tab component
const Tab: React.FC<{ label: string; }> = (props) => (
	<div data-testid="Tab" className={styles.tab}>
		{props.label}
	</div>
);

type MenuItem = Required<MenuProps>["items"][number];

export interface NavBarProps { }
export interface NavBarMethods {
	onContact?: () => void;
	onNews?: () => void;
}
const NavBar: React.ForwardRefRenderFunction<NavBarProps, NavBarMethods> = (props, ref) => {
	const { t, i18n } = useTranslation();
	const sizeBreakpoint = useSizeBreakpoint();
	const navigate = useNavigate();

	let logo = "images/Spatialnetics_Logo_Outlined_Purple.png";

	const handleLanguageChange = (language: "en" | "fr") => {
		i18n.changeLanguage(language);
		localStorage.setItem(LANGUAGE_KEY, language);
	};

	useImperativeHandle(ref, () => ({
		onContact, onNews
	}));

	function onContact() {
		onChangeTab(contact);
	}

	function onNews() {
		onChangeTab(news);
	}

	function onChangeTab(item: any) {
		if (item.key === NavBarItems.LANGUAGE) return;
		setSelectedTab(item.key);
		navigate(item.key);
	}

	let currentLanguage = Language.EN;
	function getDefaultLanguage(): Language.EN | Language.FR {

		// First check for language in Local storage
		const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
		if (storedLanguage) {
			if (storedLanguage == Language.FR) {
				currentLanguage = Language.FR;
			}
		}
		// Fall back on broswer's default language
		else {
			const browserLanguage = i18n.language == Language.FR ? Language.FR : Language.EN;
			if (browserLanguage == Language.FR) {
				currentLanguage = Language.FR;
			}
			localStorage.setItem(LANGUAGE_KEY, currentLanguage);
		}

		return currentLanguage;
	}

	function onLogo() {
		if (home?.key !== undefined) {
			setSelectedTab(home.key);
		}
		navigate("");
	}

	// Language button
	const languageBtn: MenuItem = {
		key: NavBarItems.LANGUAGE,
		label: (
			<Select
				defaultValue={getDefaultLanguage()}
				bordered={false}
				onChange={handleLanguageChange}
				options={SUPPORTED_LANGUAGES}
			/>
		),
	};

	// Home tab
	const home: MenuItem = {
		key: NavBarItems.HOME,
		label: <Tab label={t("navbar.home")}></Tab>,
	};

	// Services
	const services: MenuItem = {
		key: NavBarItems.SERVICES,
		label: t("navbar.services"),
	};

	// Projects tab
	const projects: MenuItem = {
		key: NavBarItems.PROJECTS,
		label: t("navbar.projects"),
	};

	// Solutions tab
	const solutions: MenuItem = {
		key: NavBarItems.SOLUTIONS,
		label: t("navbar.solutions"),
		children: [services, projects],
	};

	// Mission
	const mission: MenuItem = {
		key: NavBarItems.MISSION,
		label: t("navbar.mission"),
	};

	// Team
	const team: MenuItem = {
		key: NavBarItems.TEAM,
		label: t("navbar.team"),
	};

	// Careers
	const careers: MenuItem = {
		key: NavBarItems.CAREERS,
		label: t("navbar.careers"),
	};

	// News tab
	const news: MenuItem = {
		key: NavBarItems.NEWS,
		label: t("navbar.news"),
	};

	// About tab
	const about: MenuItem = {
		key: NavBarItems.ABOUT,
		label: t("navbar.about"),
		children: [mission, team, news, careers],
	};

	// Partners tab
	const partners: MenuItem = {
		key: NavBarItems.PARTNERS,
		label: t("navbar.partners"),
	};

	// Contact tab
	const contact: MenuItem = {
		key: NavBarItems.CONTACT,
		label: t("navbar.contact"),
	};

	const tabs: MenuItem[] = [languageBtn, home, solutions, about, partners, contact];
	const [selectedTab, setSelectedTab] = React.useState(home.key);
	function getLogoClass(): string {
		let classStr = "logo";
		if (sizeBreakpoint.width === ScreenSize.XXS) return `${classStr} ${styles.logoXxs}`;
		if (sizeBreakpoint.width === ScreenSize.XS) return `${classStr} ${styles.logoXs}`;
		if (sizeBreakpoint.width === ScreenSize.SM) return `${classStr} ${styles.logoSm}`;
		return `${classStr} ${styles.logoDefault}`;
	}

	React.useEffect(() => {
		i18n.changeLanguage(currentLanguage);
		const currentTab = window.location.href.split('/').pop();
		if (currentTab) {
			setSelectedTab(currentTab);
			navigate(currentTab);
		}
	}, []);

	return (
		<div className={styles.NavBar} data-testid="NavBar">
			<div className={styles.logo} onClick={onLogo}>
				<img className={getLogoClass()} src={logo} alt="logo" />
			</div>

			<div className="App">
				<Menu
					mode="horizontal"
					theme="dark"
					selectedKeys={[String(selectedTab)]}
					defaultSelectedKeys={["language"]}
					onClick={onChangeTab}
					items={tabs}
					triggerSubMenuAction="hover"
				>
				</Menu>
			</div>
		</div>
	);
};

export default forwardRef(NavBar);
