import { Row } from "antd";
import React, { FC } from "react";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import { ServiceInfo } from "../../../models/ServiceInfo";
import styles from "./Service.module.css";

interface ServiceProps {
	service: ServiceInfo;
}

const Service: FC<ServiceProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();

	function getImageClass(): string {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.imageXl;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.imageLg;
		if (sizeBreakpoint.combined === ScreenSize.MD) return styles.imageMd;
		if (sizeBreakpoint.combined === ScreenSize.SM) return styles.imageSm;
		if (sizeBreakpoint.combined === ScreenSize.XS) return styles.imageXs;
		return styles.imageXxs;
	}

	function getTitleClass() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.titleXl;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.titleLg;
		if (sizeBreakpoint.combined === ScreenSize.MD) return styles.titleMd;
		if (sizeBreakpoint.combined === ScreenSize.SM) return styles.titleSm;
		if (sizeBreakpoint.combined === ScreenSize.XS) return styles.titleXs;
		return styles.titleXxs;
	}

	function getDescriptionClass() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.descriptionXl;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.descriptionLg;
		if (sizeBreakpoint.combined === ScreenSize.MD) return styles.descriptionMd;
		if (sizeBreakpoint.combined === ScreenSize.SM) return styles.descriptionSm;
		if (sizeBreakpoint.combined === ScreenSize.XS) return styles.descriptionXs;
		return styles.descriptionXxs;
	}

	function getBulletsClass(): string {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.bulletsXl;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.bulletsLg;
		if (sizeBreakpoint.combined === ScreenSize.MD) return styles.bulletsMd;
		if (sizeBreakpoint.combined === ScreenSize.SM) return styles.bulletsSm;
		if (sizeBreakpoint.combined === ScreenSize.XS) return styles.bulletsXs;
		return styles.bulletsXxs;
	}
	return (
		<Row justify="center" className={styles.Service} data-testid="Service">

			<div className={`${styles.overlap} ${getImageClass()}`}>
				{/* Image */}
				<div className={`${styles.overlapContent}`}>
					<img
						src={props.service.src}
						className={`${styles.image} ${getImageClass()}`}
						alt={props.service.title}
					/>
				</div>
				{/* Description */}
				<div className={`${styles.overlapContent} ${styles.descriptionContainer} ${getImageClass()}`}>

					<div className={styles.serviceHeader} data-testid="ServiceHeader">
						{/* Title */}
						<h1 className={`${styles.title} ${getTitleClass()}`}>{props.service.title}</h1>
						{/* Description */}
						<p className={`${styles.description} ${getDescriptionClass()}`}>{props.service.description}</p>
					</div>

					{/* Bullets */}
					{props.service.bullets && (
						<div>
							<ul className={`${styles.bullets} ${getBulletsClass()}`}>
								{props.service.bullets.map((bullet, index) => (
									<li key={index}>{bullet}</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</Row>
	);
};

export default Service;
