import { Col, Row } from "antd";
import React, { FC } from "react";
import useSizeBreakpoint, { ScreenSize } from "../../../Hooks/SizeBreakpoint";
import DescriptionWithLink from "../../TabContentHeader/DescriptionWithLink/DescriptionWithLink";
import { Project } from "../Projects";
import styles from "./ProjectLarge.module.css";

interface ProjectLargeProps {
	card: Project;
	cardIdx: number;
}

const ProjectLarge: FC<ProjectLargeProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();
	function getImageStyle() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.imageXL;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.imageLG;
		return styles.imageMD;
	}

	function getDetailsStyle() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.detailsXL;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.detailsLG;
		return styles.detailsMD;
	}

	function getTitleStyle() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.titleXL;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.titleLG;
		return styles.titleMD;
	}

	function getDescriptionStyle() {
		if (sizeBreakpoint.combined === ScreenSize.XL) return styles.descriptionXL;
		if (sizeBreakpoint.combined === ScreenSize.LG) return styles.descriptionLG;
		return styles.descriptionMD;
	}
	return (
		<div className={styles.ProjectLarge} data-testid="ProjectLarge">
			<Row className={styles.row} justify={props.cardIdx % 2 === 0 ? "start" : "end"}>
				<Row align="middle">
					{/* Image */}
					<Col order={props.cardIdx % 2 === 0 ? 1 : 2}>
						<img className={`${styles.image} ${getImageStyle()}`} alt="example" src={props.card.src} />
					</Col>
					{/* Details */}
					<Col
						className={`${styles.details} ${props.cardIdx % 2 === 0 ? styles.overlapLeft : styles.overlapRight
							} ${getDetailsStyle()}`}
						order={props.cardIdx % 2 === 0 ? 2 : 1}
					>
						{/* Title */}
						<h1 className={`${styles.title} ${getTitleStyle()}`}>{props.card.title}</h1>
						{/* Description */}
						<p className={`${styles.description} ${getDescriptionStyle()}`}>
							<DescriptionWithLink
								description={props.card.description}
								link={props.card.clientLink}
								email={false}
								title={props.card.clientTitle}
							></DescriptionWithLink>
						</p>
					</Col>
				</Row>
			</Row>
		</div>
	);
};

export default ProjectLarge;
