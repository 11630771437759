import { ReactSVG } from "react-svg";
import "../App.css";

// Render svg based on source and custom style
export function getSVG(src: string, style: any = {}): React.ReactNode {
	return (
		<div className="svg-container">
			<ReactSVG src={src} aria-label={src} style={style} />
		</div>
	);
}

// Get CSS value of a variable defined in App.css
export function getCSSVariableValue(variableName: string) {
	const computedStyle = getComputedStyle(document.documentElement);
	return computedStyle.getPropertyValue(variableName).trim();
}

// Get CSS value of a variable defined in App.css
export function getCSSVariableBaseValue(variableName: string) {
	const computedStyle = getComputedStyle(document.documentElement);
	const val = computedStyle.getPropertyValue(variableName);
	const valTrimmed = val.trim();
	const unitRegex = /[a-zA-Z]+/g;
	const noUnits = Number(valTrimmed.replace(unitRegex, ''));
	return noUnits;
}
