import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import React, { FC } from "react";
import { CardInfo } from "../../../models/CardInfo";
import styles from "./BasicCard.module.css";

interface BasicCardProps {
	card: CardInfo;
	hoverable?: boolean;
	width?: string;
	height?: string;
	extra?: React.ReactNode;
}

const BasicCard: FC<BasicCardProps> = (props) => {
	return (
		<div className={styles.BasicCard} data-testid="BasicCard">
			<Card
				className={styles.card}
				hoverable={props.hoverable}
				style={{ width: props.width, height: props.height }}
				cover={props.card.svg && props.card.svg}
			>
				<Meta
					title={<h1 className={styles.title}>{props.card.title}</h1>}
					description={<p className={styles.description}>{props.card.description}</p>}
				/>
				{props.extra ? props.extra : null}
			</Card>
		</div>
	);
};

export default BasicCard;
