import { Card, Collapse } from "antd";
import React, { FC, useEffect } from "react";
import { TeamMember } from "../../../models/TeamMember";
import MemberDetails from "../MemberDetails/MemberDetails";
import MemberHeader from "../MemberHeader/MemberHeader";
import styles from "./MemberSmall.module.css";

interface MemberSmallProps {
	member: TeamMember;
	onClick: () => void;
}

// Display a team member card, with details at the bottom if the card was clicked to be expanded
const MemberSmall: FC<MemberSmallProps> = (props) => {
	const { Panel } = Collapse;
	const [expanded, setExpanded] = React.useState(props.member.expanded ? 1 : []);

	useEffect(() => {
		setExpanded(props.member.expanded ? 1 : []);
	}, [props.member.expanded]);
	return (
		<div className={styles.MemberSmall} data-testid="MemberSmall">
			<Card className={props.member.expanded ? "" : styles.card} hoverable={true} bodyStyle={{ padding: 0 }} onClick={props.onClick}>
				<MemberHeader
					member={props.member}
					expandedDown={props.member.expanded}
					expandedRight={false}
				></MemberHeader>
				<Collapse ghost={true} activeKey={expanded}>
					<Panel showArrow={false} key={1} header={null}>
						<MemberDetails member={props.member} large={false} />
					</Panel>
				</Collapse>
			</Card>
		</div>
	);
};

export default MemberSmall;
