import React, { FC } from "react";
import { TeamMember } from "../../../models/TeamMember";
import styles from "./MemberHeader.module.css";

interface MemberProps {
	member: TeamMember;
	expandedDown: boolean;
	expandedRight: boolean;
	onClose?: () => void;
}

// Display a team member photo with name and position
const MemberHeader: FC<MemberProps> = (props) => {
	return (
		<div className={styles.MemberHeader} data-testid="MemberHeader">
			<div className={styles.overlap}>
				{/* Photo */}
				<div className={`${styles.overlapContent}`}>
					<img
						className={`${styles.image} ${props.expandedRight ? styles.imageExpandedRight : null}`}
						alt=""
						src={props.member.src}
					/>
				</div>
				{/* Name and position */}
				<div
					className={`${styles.overlapContent} ${styles.titleContainer} ${props.expandedDown ? styles.titleContainerExpandedDown : null
						}`}
				>
					<h1 className={styles.title}>{props.member.title}</h1>
					<h2 className={styles.description}>{props.member.description}</h2>
				</div>
			</div>
		</div>
	);
};

export default MemberHeader;
