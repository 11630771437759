import React, { FC } from "react";
import styles from "./TabContentHeader.module.css";
import { Row } from "antd";
import useSizeBreakpoint, { ScreenSize } from "../../Hooks/SizeBreakpoint";
import { getCSSVariableValue } from "../../images/util";

interface TabContentHeaderProps {
	title: string;
	description: string | React.ReactNode;
	titleColor?: string;
	descriptionColor?: string;
}

const TabContentHeader: FC<TabContentHeaderProps> = (props) => {
	const sizeBreakpoint = useSizeBreakpoint();
	function getTitleFontSize(): string {
		if (sizeBreakpoint.combined === ScreenSize.XL) return "var(--font-lg)";
		if (sizeBreakpoint.combined === ScreenSize.LG) return "var(--font-lg)";
		if (sizeBreakpoint.combined === ScreenSize.MD) return "var(--font-md)";
		if (sizeBreakpoint.combined === ScreenSize.SM) return "var(--font-md)";
		return "var(--font-sm)";
	}
	function getDescriptionFontSize(): string {
		if (sizeBreakpoint.combined === ScreenSize.XL) return "var(--font-md)";
		if (sizeBreakpoint.combined === ScreenSize.LG) return "var(--font-md)";
		if (sizeBreakpoint.combined === ScreenSize.MD) return "var(--font-sm)";
		if (sizeBreakpoint.combined === ScreenSize.SM) return "var(--font-sm)";
		return "var(--font-xs)";
	}
	return (
		<div className={styles.TabContentHeader} data-testid="TabContentHeader">
			<Row justify="center">
				<h1
					style={{
						fontSize: getTitleFontSize(),
						color: props.titleColor ? props.titleColor : getCSSVariableValue("sn-primary"),
					}}
					className={styles.title}
				>
					{props.title}
				</h1>
			</Row>
			<Row justify="center">
				<h2
					style={{
						fontSize: getDescriptionFontSize(),
						color: props.descriptionColor ? props.descriptionColor : getCSSVariableValue("sn-secondary"),
					}}
					className={styles.description}
				>
					{props.description}
				</h2>
			</Row>
		</div>
	);
};

export default TabContentHeader;
