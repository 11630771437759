import i18next from "i18next";
import { ScreenSize } from "../Hooks/SizeBreakpoint";

export enum Language {
	EN = "en",
	FR = "fr",
}
export const SUPPORTED_LANGUAGES = [
	{ value: Language.EN, label: "EN" },
	{ value: Language.FR, label: "FR" },
];
// Load the current translation file
async function loadTranslationJson() {
	const currentLanguage = i18next.language;

	try {
		const translationData = await i18next.getResourceBundle(currentLanguage, "translation");
		return translationData;
	} catch (error) {
		console.error("Error loading translation JSON:", error);
		return null;
	}
}

export function onChangeLanguage(callback: () => void) {
	// Initialize cards
	callback();

	// Callback when the language changes
	const handleLanguageChange = () => {
		callback();
	};

	// Subscribe to language change events
	i18next.on("languageChanged", handleLanguageChange);

	// Cleanup: Unsubscribe from language change events when component unmounts
	return () => {
		i18next.off("languageChanged", handleLanguageChange);
	};
}

export default loadTranslationJson;

export function getClassName(size: number, styles: any, prefix: string): string{
    if (size === ScreenSize.XL) return styles[`${prefix}XL`];
    if (size === ScreenSize.LG) return styles[`${prefix}LG`];
    if (size === ScreenSize.MD) return styles[`${prefix}MD`];
    if (size === ScreenSize.SM) return styles[`${prefix}SM`];
    return styles[`${prefix}XS`];
}
