import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import { BasicHeader } from "../../models/BasicHeader";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import styles from "./Projects.module.css";
import useSizeBreakpoint, { ScreenSize } from "../../Hooks/SizeBreakpoint";
import ProjectLarge from "./ProjectLarge/ProjectLarge";
import ProjectSmall from "./ProjectSmall/ProjectSmall";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";

interface ProjectsProps { }

export interface Project extends BasicHeader {
	clientLink: string;
	clientTitle: string;
}

const Projects: FC<ProjectsProps> = (props) => {
	const [cards, setCards] = React.useState<Project[] | undefined>(undefined);
	const { t, i18n } = useTranslation();
	const sizeBreakpoint = useSizeBreakpoint();

	// Initialize cards from current translation file
	function loadItems(json: any) {
		const items: Project[] = [];
		if (!json?.projects?.cards) return;
		for (let card of json.projects.cards) {
			items.push({
				title: card.title,
				description: card.description,
				src: card.src,
				clientLink: card.client.link,
				clientTitle: card.client.title,
			});
		}
		setCards(items);
	}

	// Get the current translation file content to initialize cards
	const initCards = async () => {
		const translationData = await loadTranslationJson();
		// Access the translated JSON data and perform operations
		loadItems(translationData);
	};

	function getMainStyle() {
		if (sizeBreakpoint.width === ScreenSize.XL) return styles.XL;
		if (sizeBreakpoint.width === ScreenSize.LG) return styles.LG;
		if (sizeBreakpoint.width === ScreenSize.MD) return styles.MD;
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.SM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.XS;
		return styles.XXS;
	}

	function getContentStyle() {
		if (sizeBreakpoint.width === ScreenSize.XL) return styles.contentXL;
		if (sizeBreakpoint.width === ScreenSize.LG) return styles.contentLG;
		if (sizeBreakpoint.width === ScreenSize.MD) return styles.contentMD;
		if (sizeBreakpoint.width === ScreenSize.SM) return styles.contentSM;
		if (sizeBreakpoint.width === ScreenSize.XS) return styles.contentXS;
		return styles.contentXXS;
	}

	function isLarge(): boolean {
		if (typeof sizeBreakpoint.width !== "number") {
			return false;
		}
		return Number(sizeBreakpoint.width) >= ScreenSize.MD;
	}

	React.useEffect(() => {
		onChangeLanguage(initCards);
	}, [i18n]);
	return (
		<div className={`${styles.Projects} ${getMainStyle()}`} data-testid="Projects">
			<TabContentHeader
				title={t("projects.header.title")}
				description={t("projects.header.description")}
			></TabContentHeader>
			<Layout className={styles.layout}>
				<Content className={`${styles.content} ${getContentStyle()}`}>
					{cards &&
						cards.map((card: Project, index: number) =>
							isLarge() ? (
								<ProjectLarge key={index} card={card} cardIdx={index}></ProjectLarge>
							) : (
								<ProjectSmall key={index} card={card} cardIdx={index}></ProjectSmall>
							)
						)}
				</Content>
			</Layout>
		</div>
	);
};

export default Projects;
