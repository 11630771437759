import React, { FC } from 'react';
import styles from './NoData.module.css';
import { Divider, Empty } from 'antd';
import { useTranslation } from 'react-i18next';

interface NoDataProps { }

const NoData: FC<NoDataProps> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.NoData} data-testid="NoData">
      <div className={styles.empty}>
        <Divider></Divider>
        <Empty description={t('general.noData')}></Empty>
        <Divider></Divider>
      </div>
    </div>
  );
};

export default NoData;
