import React from "react";
import { Layout } from "antd";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Content, Footer, Header } from "antd/es/layout/layout";
import NavBar, { NavBarItems, NavBarMethods } from "./components/NavBar/NavBar";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import useSizeBreakpoint from "./Hooks/SizeBreakpoint";
import Home from "./components/Home/Home";
import Mission from "./components/Mission/Mission";
import Services from "./components/Services/Services";
import Team from "./components/Team/Team";
import Partners from "./components/Partners/Partners";
import Contact from "./components/Contact/Contact";
import Projects from "./components/Projects/Projects";
import Careers from "./components/Careers/Careers";
import News from "./components/News/News";

function App() {
	const { t } = useTranslation();

	const sizeBreakpoint = useSizeBreakpoint();
	const navigate = useNavigate();
	const navBarRef = React.useRef<NavBarMethods | null>(null);

	function getYear(): string {
		return new Date().getFullYear().toString();
	}

	function onContact() {
		if (navBarRef && navBarRef.current) {
			navBarRef.current.onContact?.();
			navigate(NavBarItems.CONTACT);
		}
	}
	function onNews() {
		if (navBarRef && navBarRef.current) {
			navBarRef.current.onNews?.();
			navigate(NavBarItems.NEWS);
		}
	}
	return (
		<div className="App">
			<Layout className="layout">
				<Header>
					<NavBar ref={navBarRef}></NavBar>
				</Header>
				<Content>
					<div>
						<Routes>
							<Route path="/" element={<Home onContact={onContact} onReadMore={onNews} />} />
							<Route path={NavBarItems.PROJECTS} element={<Projects></Projects>} />
							<Route path={NavBarItems.SERVICES} element={<Services></Services>} />
							<Route path={NavBarItems.MISSION} element={<Mission></Mission>} />
							<Route path={NavBarItems.TEAM} element={<Team></Team>} />
							<Route path={NavBarItems.CAREERS} element={<Careers onContact={onContact}></Careers>} />
							<Route path={NavBarItems.NEWS} element={<News></News>} />
							<Route path={NavBarItems.PARTNERS} element={<Partners onContact={onContact}></Partners>} />
							<Route path={NavBarItems.CONTACT} element={<Contact></Contact>} />
							<Route path="*" element={<Navigate to="/" />} />
						</Routes>
					</div>
				</Content>

				<Footer>
					<div className="footer">
						<span className="copyright">&copy;</span>
						<span className="copyright">{t("footer.copyright", { year: getYear() })}</span>
					</div>
				</Footer>
			</Layout>
		</div>
	);
}

export default App;
