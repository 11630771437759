import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import loadTranslationJson, { onChangeLanguage } from "../../locales/util";
import { INFO_EMAIL } from "../Contact/Contact";
import ContactButton from "../ContactButton/ContactButton";
import DescriptionWithLink from "../TabContentHeader/DescriptionWithLink/DescriptionWithLink";
import TabContentHeader from "../TabContentHeader/TabContentHeader";
import styles from "./Careers.module.css";

interface CareersProps {
	onContact: () => void;
}

const Careers: FC<CareersProps> = (props) => {
	const [description, setDescription] = React.useState<string>("");
	const { t, i18n } = useTranslation();

	// Initialize cards from current translation file
	function loadDescription(json: any) {
		if (!json?.careers?.header?.description) return;
		const newDescription = String(json.careers.header.description);
		setDescription(newDescription);
	}

	const initDescription = async () => {
		const translationData = await loadTranslationJson();
		loadDescription(translationData);
	};

	React.useEffect(() => {
		onChangeLanguage(initDescription);
	}, [i18n]);

	return (
		<div className={styles.Careers} data-testid="Careers">
			<TabContentHeader
				title={t("careers.header.title")}
				description={
					<div>
						<DescriptionWithLink
							description={description}
							email={true}
							link={INFO_EMAIL}
						></DescriptionWithLink>
					</div>
				}
			></TabContentHeader>
			<br></br>
			<ContactButton
				label={t("general.contactLabel")}
				onClick={props.onContact}
				htmlType="button"
				type="default"
			></ContactButton>
		</div>
	);
};

export default Careers;
