import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

const styles = getComputedStyle(document.documentElement);
const sn_primary = styles.getPropertyValue("--sn-primary");
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: require("./locales/en/translation.json"),
		},
		fr: {
			translation: require("./locales/fr/translation.json"),
		},
	},
	lng: navigator.language.includes("fr") ? "fr" : "en",
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});
root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: sn_primary,
				},
			}}
		>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</I18nextProvider>
		</ConfigProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
